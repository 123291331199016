.MuiDataGrid-row.red-row,
.MuiDataGrid-row.red-row:hover{
  background-color:rgba(250,128,114,0.5) !important;
}


.react-datepicker-wrapper input {
  background-color: transparent;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width:100%;
  padding: 19px 14px;
  cursor: pointer;
}

.react-datepicker-wrapper input:focus {
  border: 1px solid #bfbfbf;
}

.react-datepicker-wrapper input:hover {
  border: 1px solid black;
}

/* .form-group{
  margin-top: 12px;
} */